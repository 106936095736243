.checkoutProduct {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
}

.checkoutProduct__indo {
    padding-left: 20px;
}

.checkoutProduct__info > button {
    background-color: #f0c14b;
    border: 1px solid;
    margin-top: 10px;
    border-color: #a88734 #9c7e31 #846a29;
    color: #111;
}

.checkoutProduct__image {
    padding-right: 20px;
    
    object-fit: contain;
    width: 200px;
    height: 180px;
}

.checkoutProduct__rating {
    padding-right: 20px;
    display: flex;

}

.checkoutProduct__title {
    padding-right: 20px;
    font-size: 17px;
    font-weight: 800;

}